import { v4 as uuidv4 } from "uuid";

export function getExtension(filename: string): string {
  // 最後のピリオドの位置を見つける
  const index = filename.lastIndexOf(".");

  // ピリオドが見つからないか、ファイル名の最後にピリオドがある場合は空の文字列を返す
  if (index === -1 || index === filename.length - 1) {
    return "";
  }

  // ピリオドの次の文字から文字列の終わりまでを返す
  return filename.substring(index + 1);
}

export function generateUUID(): string {
  return uuidv4(); // UUID v4を生成して返す
}

/**
 * 数値をカンマ区切りの文字列に変換する関数
 * @param amount - 金額（数値）
 * @returns カンマ区切りの金額（文字列）
 */
export const formatCurrency = (amount: number): string => {
  return amount
    .toLocaleString("ja-JP", { style: "currency", currency: "JPY" })
    .replace("￥", "");
};

/**
 * 現在の日付をYYYY年MM月DD日の形式で取得する関数
 * @returns 現在の日付（文字列）
 */
export const getFormattedToday = (): string => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0"); // 月は0から始まるため+1
  const day = String(now.getDate()).padStart(2, "0");

  return `${year}年${month}月${day}日`;
};

/**
 * ISO 8601形式の日時をYYYY年MM月DD日の形式で取得する関数
 * 時間部分はトリミングされます。
 * @returns YYYY年MM月DD日
 */
export const getformatDateFromISOFormat = (
  dateString: string | null
): string | null => {
  if (dateString === null) {
    return null;
  }
  const datePart = dateString.split("T")[0]; // '2024-05-28'
  const [year, month, day] = datePart.split("-");
  return `${year}年${month}月${day}日`;
};

/**
 * JSTのISO 8601形式の文字列をDateから生成します。DateはUTCで入力されます。
 * @param date
 * @returns JSTのISO 8601形式の文字列
 */
export const toJSTISOString = (date: Date): string => {
  const jstDate = new Date(date.getTime() + 9 * 60 * 60 * 1000); // JSTに変換

  const year = jstDate.getUTCFullYear();
  const month = String(jstDate.getUTCMonth() + 1).padStart(2, "0");
  const day = String(jstDate.getUTCDate()).padStart(2, "0");
  const hours = String(jstDate.getUTCHours()).padStart(2, "0");
  const minutes = String(jstDate.getUTCMinutes()).padStart(2, "0");
  const seconds = String(jstDate.getUTCSeconds()).padStart(2, "0");
  const milliseconds = String(jstDate.getUTCMilliseconds()).padStart(3, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}+09:00`;
};


/**
 * 現在の時刻からJSTの年月日を生成します。
 * @returns JSTのyyyyMMdd
 */
export const getCurrentDateInJST = (): string => {
  // 現在の日時を取得
  const now = new Date();

  // 日本標準時に変換するため、UTC時間に日本の時差（9時間）を足す
  const jstOffset = 9 * 60 * 60 * 1000;
  const jstNow = new Date(now.getTime() + jstOffset);

  // 年、月、日を取得し、「yyyyMMdd」形式にフォーマット
  const year = jstNow.getUTCFullYear();
  const month = String(jstNow.getUTCMonth() + 1).padStart(2, "0"); // 月は0から始まるため+1
  const day = String(jstNow.getUTCDate()).padStart(2, "0");

  return `${year}${month}${day}`;
};
