import React from "react";
import {
  Container,
  Typography,
  Paper,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
} from "@mui/material";

interface ProvisoProps {
  content: string;
}

const Proviso: React.FC<ProvisoProps> = ({ content }) => {
  return (
    <Paper
      sx={{
        marginTop: 2,
        padding: 2,
        // textAlign: "center",
        border: "2px dashed #aaa",
        maxHeight: 200,
        overflowY: "auto",
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </Paper>
  );
};

export default Proviso;
