// src/pages/Home.tsx
import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

// componets
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import FileUpload from "../components/FileUpload/FileUpload";
import EstimationList from "../components/EstimationList/EstimationList";
import { theme } from "../components/Color/color";
// interfaces
// import { ListItemData } from "../interfaces/ListItemData/ListItemData";
import { Container, Button, Stack } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";

// redux
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { estimationAmountState } from "../features/estimationAmount/estimationAmountSlice";
import { selectEstimationAmountList } from "../features/estimationAmountList/estimationAmountListSlice";
import { selectEstimationAmountDisplay } from "../features/isEstimationAmountDisplay/isEstimationAmountDisplaySlice";
import {
  changeProcess,
  changeDisplay,
} from "../features/managementProcess/managementProcessSlice";

const Home: React.FC = () => {
  // redux
  const estimateAmountList: estimationAmountState[] = useAppSelector(
    selectEstimationAmountList
  );
  const estimationAmountDisplay = useAppSelector(selectEstimationAmountDisplay);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(changeDisplay("Home"));
  }, [dispatch]);

  const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);

  // ボタンの有効/無効を判定するメソッド
  const checkButtonEnable = (list: estimationAmountState[]) => {
    const hasCheckedItem = list.some((item) => item.checkBox);
    setIsButtonEnabled(hasCheckedItem);
  };
  // コンポーネントがマウントされた時に一度チェックを行う
  useEffect(() => {
    checkButtonEnable(estimateAmountList);
  }, [estimateAmountList]);

  // 見積、発注ボタン押下時のハンドリング処理
  const navigate = useNavigate();
  const handleButtonClick = (status: string) => {
    if (status === "estimate") {
      // 見積ボタンの場合
      dispatch(changeProcess("estimate"));
      navigate("/input-customer-info");
    } else if (status === "order") {
      // 発注ボタンの場合
      dispatch(changeProcess("order"));
      navigate("/input-customer-info");
    } else {
      // 何もしない
    }
  };

  return (
    <div>
      <Header />
      <Container style={{ marginTop: "16px", marginBottom: "100px" }}>
        <Box
          border={2}
          borderColor="grey.500"
          style={{ borderStyle: "solid", padding: 16 }}
        >
          <FileUpload />
        </Box>
        <Box>
          <EstimationList
            listItems={estimateAmountList}
            isDisplay={estimationAmountDisplay}
          />
        </Box>
        <Box
          sx={{
            margin: "10px 200px",
          }}
        >
          <Stack direction="row" spacing={2}>
            {/* ダウンロードボタン */}
            {isButtonEnabled ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  // disabled={!checked} // チェックが入っていない場合は非アクティブ
                  onClick={() => {
                    // ダウンロード処理を行う関数
                    handleButtonClick("estimate");
                  }}
                >
                  選択中の見積をまとめてダウンロード
                </Button>
                <ThemeProvider theme={theme}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => {
                      //ダウンロード処理
                      handleButtonClick("order");
                    }}
                  >
                    選択中の見積をすべて発注
                  </Button>
                </ThemeProvider>
              </>
            ) : (
              <>
                <Button variant="contained" color="primary" fullWidth disabled>
                  選択中の見積をまとめてダウンロード
                </Button>
                <ThemeProvider theme={theme}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    disabled
                  >
                    選択中の見積をすべて発注
                  </Button>
                </ThemeProvider>
              </>
            )}
          </Stack>
        </Box>
        {/* <Box>開発用</Box>
        <Button>
          <Link to="/">ホーム</Link>
        </Button>
        <Button>
          <Link to="/input-customer-info">顧客情報入力画面</Link>
        </Button>
        <Button>
          <Link to="/estimation-display">見積もり表示ページ</Link>
        </Button>
        <Button>
          <Link to="/test-page">テストページ</Link>
        </Button> */}
      </Container>
      <Footer />
    </div>
  );
};

export default Home;
