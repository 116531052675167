import React from "react";
import {
  Dialog,
  DialogContent,
  CircularProgress,
  Typography,
  Box,
} from "@mui/material";

// ダイアログコンポーネント
const LoadingDialog: React.FC<{ isOpen: boolean; message: string }> = ({
  isOpen,
  message,
}) => {
  return (
    <>
      {isOpen && (
        <Box
          sx={{
            position: "fixed",
            zIndex: 1300, // Material-UIのバックドロップと同じz-index
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)", // 半透明の背景色
            pointerEvents: "none", // マウスイベントを無効化
          }}
        />
      )}
      <Dialog open={isOpen} aria-labelledby="loading-dialog">
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* ローディングメッセージ */}
          <Typography variant="h6">{message}</Typography>
          {/* プログレスバー */}
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LoadingDialog;
