import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";

export interface managementProcess {
  process: string;
  display: string;
  status: "loading" | "succeeded" | "failed" | string;
}
const initialState: managementProcess = {
  process: "",
  display: "",
  status: "loading",
};

export const managementProcessSlice = createSlice({
  name: "managementProcess",
  initialState,
  reducers: {
    changeProcess: (state, action: PayloadAction<string>) => {
      const process = action.payload;
      state.process = process;
    },
    changeDisplay: (state, action: PayloadAction<string>) => {
      const display = action.payload;
      state.display = display;
    },
  },
});

export const { changeProcess, changeDisplay } = managementProcessSlice.actions;
export const selectManagementProcess = (state: RootState) =>
  state.managementProcess;
export default managementProcessSlice.reducer;
