// Header.tsx
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  ThemeProvider,
  Button,
} from "@mui/material";
import { theme } from "../Color/color";
import logo from "../../images/rakuCADtrace_logo.png";

interface HeaderProps {
  pageTitle?: string;
}

const Header: React.FC<HeaderProps> = ({ pageTitle }) => {
  // ナビゲーション
  const navigate = useNavigate();
  return (
    <AppBar position="static">
      <Toolbar
        sx={{ backgroundColor: "#ffffff", borderBo: "1px solid #CCCCCC" }}
      >
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Box sx={{ flex: "0 1 16%" }}>
            <img
              src={logo}
              alt="raku cad trace"
              style={{ height: 60, marginLeft: 0, marginBottom: 4 }}
            />
          </Box>
          <Box sx={{ flex: "0 1 44%" }}>
            {pageTitle && (
              <Typography
                variant="h5"
                color="black"
                sx={{ paddingLeft: "20px" }}
              >
                {pageTitle}
              </Typography>
            )}
          </Box>
          <Box sx={{ flex: "0 1 40%", textAlign: "right" }}>
            <ThemeProvider theme={theme}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("/");
                }}
              >
                TOPへ戻る
              </Button>
            </ThemeProvider>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
