import { format, addDays, startOfWeek, endOfWeek } from "date-fns";

export function getDisableDays() {
 // 呼び出し日の当日と翌日を取得する
//  const today = new Date();
//  const tomorrow = addDays(today, 1);
//  const todayString = format(today, "yyyy-MM-dd");
//  const tomorrowString = format(tomorrow, "yyyy-MM-dd");

//  // 土曜日と日曜日の日付を計算
//  const startDate = startOfWeek(today, { weekStartsOn: 1 }); // 週の開始日(月曜日)
//  const endDate = endOfWeek(today, { weekStartsOn: 1 }); // 週の終了日(日曜日)
//  const saturday = format(addDays(startDate, 5), "yyyy-MM-dd"); // 土曜日
//  const sunday = format(addDays(startDate, 6), "yyyy-MM-dd"); // 日曜日

 // あらかじめ設定されている日付と、呼び出し日の当日と翌日、土曜日と日曜日を合わせて返す
 const disableDaysArray = [
   "2024-04-29",
   "2024-04-30",
   "2024-05-01",
   "2024-05-02",
   "2024-05-03",
 ];

 return new Promise<{ disableDaysArray: string[] }>((resolve) =>
   setTimeout(() => resolve({ disableDaysArray: disableDaysArray }), 500)
 );
}