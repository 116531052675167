import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import estimationAmountReducer from "../features/estimationAmount/estimationAmountSlice";
import estimationAmountListReducer from "../features/estimationAmountList/estimationAmountListSlice";
import isEstimationAmountDisplayReducer from "../features/isEstimationAmountDisplay/isEstimationAmountDisplaySlice";
import managementProcessReducer from "../features/managementProcess/managementProcessSlice";
import customerInfoReducer from "../features/customerInfo/customerInfoSlice";
import isFileSelectedReducer from "../features/isFileSelected/isFileSelectedSlice"

export const store = configureStore({
  reducer: {
    estimationAmount: estimationAmountReducer,
    estimationAmountList: estimationAmountListReducer,
    estimationAmountDisplay: isEstimationAmountDisplayReducer,
    managementProcess: managementProcessReducer,
    customerInfo: customerInfoReducer,
    isFileSelected: isFileSelectedReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
